/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { MenuContext } from 'App';
import { MenuContextType } from 'models/admin/Menu';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import { tabs } from 'components/layouts/Tab';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { MpItem, MpItemTpCho, TapButtonType } from 'models/mp/MpItem';
import PropTypes from 'prop-types';
import { ElmEmpPopup } from '../common/ElmEmpPopup';
import { ManagementMode } from 'models/common/Common';
import { findMpItem, generateItemId, saveMpItem, saveMpItemStat } from 'apis/mp/MpItem';
import { findMpResults, findMpResultsForValid, saveMpResult } from 'apis/mp/MpResult';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { useLoading } from 'components/process/Loading';
import MpItemInfoContent from './itemmanagement/MpItemInfoContent';
import MpItemOtherContent from './itemmanagement/MpItemOtherContent';

interface DisplayCondition {
  reqMgrNm?: string; // 기술제안자명
  dataInsUserNm?: string; // 등록자명
}

interface PageLocationState {
  itemId?: string;
  mode?: ManagementMode;
  itemTpCd?: string;
  copCd?: string;
  plntCd?: string;
  lnId?: string;
  copyTarget?: MpItem;
}

const MpItemManagementPage = () => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { openLoading } = useLoading();
  const firstRef = useRef(true);
  const infoRef = useRef<any>(null);
  const otherRef = useRef<any>(null);
  const [errors, setErrors] = useState<any>({});
  const menuContext = useContext<MenuContextType>(MenuContext);
  const [locationState, setLocationState] = useState<PageLocationState>(useLocation().state);
  const [mpItem, setMpItem] = useState<MpItem>({});
  const [displayCondition, setDisplayCondition] = useState<DisplayCondition>({});
  const [isOpenElmEmpPopup, setOpenElmEmpPopup] = useState<boolean>(false);
  const [empPopupCondition, setEmpPopupCondition] = useState({
    fieldId: '',
    initParam: '',
  });
  const [userData, setUserData] = useState<any>('');
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const tabMenus = useMemo(() => {
    let tabs = [{ id: 'INFO', name: t('mp.label.기본사항', '기본사항') }];
    if (locationState?.itemId && ManagementMode.MODIFY === locationState?.mode) {
      /*
      // [24.03.22] 설비변경점은 기본사항만 노출
      if (mpItem?.mpYn === 'Y') {
        tabs = [...tabs, { id: MpItemTpCho.MP, name: t('mp.label.설비변경점', '설비변경점') }];
      }
      */
      // [24.05.14] 수평전개 탭은 상태가 수평전개실행/수평전개완료 일 때만 노출 (대상상태: HE03, HE04, MPHE05, MPHE06)
      if (
        mpItem?.heYn === 'Y' &&
        ['HE03', 'HE04', 'MPHE05', 'MPHE06'].includes(mpItem?.itemStatCd || '')
      ) {
        tabs = [...tabs, { id: MpItemTpCho.HE, name: t('mp.label.수평전개', '수평전개') }];
      }
      /*
      // [23.12.07] 신규개선은 기본사항만 노출
      if (mpItem?.niYn === 'Y') {
        tabs = [...tabs, { id: MpItemTpCho.NI, name: t('mp.label.신규개선', '신규개선') }];
      }
      */
    }
    return tabs;
  }, [locationState?.itemId, locationState?.mode, mpItem?.heYn, mpItem?.itemStatCd]);

  const renderTabButtons = (type: TapButtonType) => {
    switch (type) {
      case TapButtonType.ButtonSave:
        return (
          <Button css={IconButton.button} className="save" onClick={handleSave} disableRipple>
            {t('com.button.저장', '저장')}
          </Button>
        );
      case TapButtonType.ButtonNiDevComp:
        return (
          <Button
            css={IconButton.button}
            className="chevron"
            onClick={() => handleChangeStat(TapButtonType.ButtonNiDevComp)}
            disableRipple
          >
            {t('com.button.개발완료', '개발완료')}
          </Button>
        );
      case TapButtonType.ButtonNiDrop:
        return (
          <Button
            css={IconButton.button}
            className="developstop"
            onClick={() => handleChangeStat(TapButtonType.ButtonNiDrop)}
            disableRipple
          >
            {t('com.button.Drop', 'Drop')}
          </Button>
        );
      case TapButtonType.ButtonMpReview:
        return (
          <Button
            css={IconButton.button}
            className="write"
            onClick={() => handleChangeStat(TapButtonType.ButtonMpReview)}
            disableRipple
          >
            {t('com.button.검토', '검토')}
          </Button>
        );
      case TapButtonType.ButtonMpVal:
        return (
          <Button
            css={IconButton.button}
            className="eye"
            onClick={() => handleChangeStat(TapButtonType.ButtonMpVal)}
            disableRipple
          >
            {t('com.button.검증', '검증')}
          </Button>
        );
      case TapButtonType.ButtonMpValComp:
        return (
          <Button
            css={IconButton.button}
            className="chevron"
            onClick={() => handleChangeStat(TapButtonType.ButtonMpValComp)}
            disableRipple
          >
            {t('com.button.검증완료', '검증완료')}
          </Button>
        );
      case TapButtonType.ButtonMpDrop:
        return (
          <Button
            css={IconButton.button}
            className="developstop"
            onClick={() => handleChangeStat(TapButtonType.ButtonMpDrop)}
            disableRipple
          >
            {t('com.button.Drop', 'Drop')}
          </Button>
        );
      case TapButtonType.ButtonMpHeReview:
        return (
          <Button
            css={IconButton.button}
            className="write"
            onClick={() => handleChangeStat(TapButtonType.ButtonMpHeReview)}
            disableRipple
          >
            {t('com.button.검토', '검토')}
          </Button>
        );
      case TapButtonType.ButtonMpHeVal:
        return (
          <Button
            css={IconButton.button}
            className="eye"
            onClick={() => handleChangeStat(TapButtonType.ButtonMpHeVal)}
            disableRipple
          >
            {t('com.button.검증', '검증')}
          </Button>
        );
      case TapButtonType.ButtonMpHeValComp:
        return (
          <Button
            css={IconButton.button}
            className="chevron"
            onClick={() => handleChangeStat(TapButtonType.ButtonMpHeValComp)}
            disableRipple
          >
            {t('com.button.검증완료', '검증완료')}
          </Button>
        );
      case TapButtonType.ButtonMpHeAction:
        return (
          <Button
            css={IconButton.button}
            className="tool"
            onClick={() => handleChangeStat(TapButtonType.ButtonMpHeAction)}
            disableRipple
          >
            {t('com.button.수평전개 실행', '수평전개 실행')}
          </Button>
        );
      case TapButtonType.ButtonMpHeComp:
        return (
          <Button
            css={IconButton.button}
            className="chevron"
            onClick={() => handleChangeStat(TapButtonType.ButtonMpHeComp)}
            disableRipple
          >
            {t('com.button.수평전개 완료', '수평전개 완료')}
          </Button>
        );
      case TapButtonType.ButtonMpHeDrop:
        return (
          <Button
            css={IconButton.button}
            className="developstop"
            onClick={() => handleChangeStat(TapButtonType.ButtonMpHeDrop)}
            disableRipple
          >
            {t('com.button.Drop', 'Drop')}
          </Button>
        );
      case TapButtonType.ButtonHeUnitVal:
        return (
          <Button
            css={IconButton.button}
            className="eye"
            onClick={() => handleChangeStat(TapButtonType.ButtonHeUnitVal)}
            disableRipple
          >
            {t('com.button.대표호기 검증', '대표호기 검증')}
          </Button>
        );
      case TapButtonType.ButtonHeAction:
        return (
          <Button
            css={IconButton.button}
            className="tool"
            onClick={() => handleChangeStat(TapButtonType.ButtonHeAction)}
            disableRipple
          >
            {t('com.button.수평전개 실행', '수평전개 실행')}
          </Button>
        );
      case TapButtonType.ButtonHeComp:
        return (
          <Button
            css={IconButton.button}
            className="chevron"
            onClick={() => handleChangeStat(TapButtonType.ButtonHeComp)}
            disableRipple
          >
            {t('com.button.수평전개 완료', '수평전개 완료')}
          </Button>
        );
      case TapButtonType.ButtonHeDrop:
        return (
          <Button
            css={IconButton.button}
            className="developstop"
            onClick={() => handleChangeStat(TapButtonType.ButtonHeDrop)}
            disableRipple
          >
            {t('com.button.Drop', 'Drop')}
          </Button>
        );
      default:
        return null;
    }
  };

  const tabButtons: TapButtonType[] = useMemo(() => {
    const buttons: TapButtonType[] = [];
    // 탭버튼 세팅
    if (currentTabIndex === 0) {
      if (mpItem?.itemStatCd === 'NI01') {
        // <개발완료><Drop>
        buttons.push(TapButtonType.ButtonNiDevComp, TapButtonType.ButtonNiDrop);
      } else if (mpItem?.itemStatCd === 'MP01') {
        // <검토><Drop>
        buttons.push(TapButtonType.ButtonMpReview, TapButtonType.ButtonMpDrop);
      } else if (mpItem?.itemStatCd === 'MP02') {
        // <검증><Drop>
        buttons.push(TapButtonType.ButtonMpVal, TapButtonType.ButtonMpDrop);
      } else if (mpItem?.itemStatCd === 'MP03') {
        // <검증완료><Drop>
        buttons.push(TapButtonType.ButtonMpValComp);
      } else if (mpItem?.itemStatCd === 'MPHE01') {
        // <검토><Drop>
        buttons.push(TapButtonType.ButtonMpHeReview, TapButtonType.ButtonMpHeDrop);
      } else if (mpItem?.itemStatCd === 'MPHE02') {
        // <검증><Drop>
        buttons.push(TapButtonType.ButtonMpHeVal, TapButtonType.ButtonMpHeDrop);
      } else if (mpItem?.itemStatCd === 'MPHE03') {
        // <검증완료><Drop>
        buttons.push(TapButtonType.ButtonMpHeValComp, TapButtonType.ButtonMpHeDrop);
      } else if (mpItem?.itemStatCd === 'MPHE04') {
        // <수평전개 실행><Drop>
        buttons.push(TapButtonType.ButtonMpHeAction, TapButtonType.ButtonMpHeDrop);
      } else if (mpItem?.itemStatCd === 'MPHE05') {
        // <수평전개 완료><Drop>
        buttons.push(TapButtonType.ButtonMpHeComp, TapButtonType.ButtonMpHeDrop);
      } else if (mpItem?.itemStatCd === 'HE01') {
        // <대표호기 검증><Drop>
        buttons.push(TapButtonType.ButtonHeUnitVal, TapButtonType.ButtonHeDrop);
      } else if (mpItem?.itemStatCd === 'HE02') {
        // <수평전개 실행><Drop>
        buttons.push(TapButtonType.ButtonHeAction, TapButtonType.ButtonHeDrop);
      } else if (mpItem?.itemStatCd === 'HE03') {
        // <수평전개 완료><Drop>
        buttons.push(TapButtonType.ButtonHeComp, TapButtonType.ButtonHeDrop);
      }
    }

    const excludeSave = [
      TapButtonType.ButtonNiDrop,
      TapButtonType.ButtonMpDrop,
      TapButtonType.ButtonMpHeDrop,
      TapButtonType.ButtonHeDrop,
      TapButtonType.ButtonMpValComp,
      TapButtonType.ButtonMpHeComp,
      TapButtonType.ButtonHeComp,
    ] as string[];

    if (!excludeSave.includes(mpItem?.itemStatCd || '')) {
      buttons.push(TapButtonType.ButtonSave);
    }

    return buttons;
  }, [mpItem.itemStatCd, currentTabIndex]);

  useEffect(() => {
    if (!locationState?.itemId && ManagementMode.CREATE === locationState?.mode) {
      // item id 생성 (등록)
      generateItemId().then((result) => {
        if (!result) {
          openMessageBar({
            type: 'error',
            content: t(
              'mp.msg.Item 번호 생성에 실패했습니다.<br/>새로고침 후 다시 시도해 주세요.',
              'Item 번호 생성에 실패했습니다.<br/>새로고침 후 다시 시도해 주세요.'
            ),
          });
          return;
        }

        if (locationState?.copyTarget) {
          setMpItem({
            ...(locationState.copyTarget || {}),
            itemTpChoCd: '',
            itemStatCd: '',
            mpClsfCds: (locationState?.copyTarget?.mpClsfCd || '').split(',').map((o) => o.trim()),
            itemId: result,
          });

          setDisplayCondition({
            ...displayCondition,
            reqMgrNm: locationState?.copyTarget.reqMgrNm,
          });
        } else {
          setMpItem({
            itemId: result,
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (state) {
      // 탭 변경
      changeCurrentTabIndex(state?.itemTpCd);
      if (state?.itemTpCd && state?.copCd) {
        setRefresh(true);
        otherRef.current?.reload(
          state?.itemId,
          state?.itemTpCd,
          state?.copCd,
          state?.plntCd,
          state?.lnId
        );
      }
      // 목록에서 다른 항목을 선택한 경우 (신규, 수정 탭이 모두 열려있는 경우 구분하기 위해 조건 추가)
      setLocationState((prev) => (prev.mode === state.mode ? state : prev));
    }
  }, [state]);

  const changeCurrentTabIndex = (itemTpCd) => {
    if (itemTpCd && (tabMenus || []).length) {
      // 일치하는 유형이 없는 경우 기본사항 탭
      setCurrentTabIndex(
        Math.max(
          0,
          (tabMenus || []).findIndex((o) => o.id === itemTpCd)
        )
      );
    }
  };

  useEffect(() => {
    changeCurrentTabIndex(locationState?.itemTpCd);
  }, [locationState?.itemTpCd, tabMenus]);

  useEffect(() => {
    if (locationState?.itemId && ManagementMode.MODIFY === locationState?.mode) {
      // 수정 탭이 열린 상태에서 다른 item 선택해서 수정화면 진입하는 경우 재조회
      if (mpItem?.itemId !== locationState?.itemId) {
        firstRef.current = true;
        // 재조회 전에 초기화
        initMpItem();
        loadMpItem(locationState?.itemId, true);
      }
    }
  }, [locationState?.itemId, locationState?.mode]);

  useEffect(() => {
    if (locationState?.itemId) {
      const tabId = tabMenus[currentTabIndex].id;
      if (ManagementMode.MODIFY === locationState?.mode && tabId === 'INFO' && !firstRef.current) {
        // item 정보 조회 (수정)
        loadMpItem(locationState?.itemId, true);
      }
    }
  }, [currentTabIndex]);

  useEffect(() => {
    // 사용자 조회 callback 처리
    if (userData != null && userData != '') {
      const fieldId = userData.fieldId;
      const userInfo = userData.userInfo[0] || {};
      if (fieldId) {
        setMpItem((prev) => ({
          ...prev,
          [fieldId.replace('Nm', 'Id')]: userInfo.userId,
        }));
        setDisplayCondition({
          ...displayCondition,
          [fieldId]: userInfo.empNm,
        });
      }
    }
  }, [userData]);

  /**
   * item 정보 조회
   * @param itemId
   * @param isResetTab
   */
  const loadMpItem = (itemId, isResetTab = false) => {
    findMpItem(itemId)
      .then((result) => {
        if (!result) {
          openMessageBar({
            type: 'error',
            content: t('mp.msg.Item정보가없습니다.', 'Item정보가없습니다.'),
          });
          menuContext.closeCurrentTab(menuContext);
          return;
        }

        setMpItem(result);
        // MP분류가 없는 경우에만 기본사항 탭으로 변경
        if (isResetTab && !locationState?.itemTpCd) {
          setCurrentTabIndex(0);
        }
        setDisplayCondition({
          ...displayCondition,
          reqMgrNm: result.reqMgrNm,
        });

        infoRef?.current?.handleItemChange('itemStatCd', result.itemStatCd);
        infoRef?.current?.refresh();
      })
      .finally(() => {
        if (firstRef.current) {
          firstRef.current = false;
        }
      });
  };

  const handleItemChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setMpItem((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    setErrors((prev) => ({
      ...prev,
      [e.target.name]: false,
    }));
  };

  const initMpItem = () =>
    setMpItem({
      itemId: '',
      itemNm: '',
      itemEngNm: '',
      reqMgrId: '',
      mpClsfCds: [],
      mpYn: '',
      heYn: '',
      niYn: '',
      dataInsUserNm: '',
      dataInsDtm: '',
      dataUpdUserNm: '',
      dataUpdDtm: '',
    });

  const handleDisplayConditionChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const name = e.target.name;
    setDisplayCondition((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));

    setMpItem((prev) => ({
      ...prev,
      [name.replace('Nm', 'Id')]: '',
    }));
  };

  const validate = () => {
    const err = {};
    // Item 명
    if (!mpItem?.itemNm) {
      Object.assign(err, { itemNm: true });
    }
    // Item 영문명
    if (!mpItem?.itemEngNm) {
      Object.assign(err, { itemEngNm: true });
    }
    // 기술 제안자
    if (!mpItem?.reqMgrId) {
      Object.assign(err, { reqMgrId: true });
    }
    setErrors(err);
    if (!Object.keys(err).filter((k) => err[k]).length) {
      return true;
    }
    return false;
  };

  const handleSave = () => {
    if (validate()) {
      const tabId = tabMenus[currentTabIndex].id;
      const defaultParam = {
        itemId: mpItem?.itemId,
        itemNm: mpItem?.itemNm,
        itemEngNm: mpItem?.itemEngNm,
        reqMgrId: mpItem?.reqMgrId,
      } as MpItem;

      // 기본사항 탭인 경우
      if (tabId === 'INFO') {
        if (!infoRef.current.validate()) {
          return;
        }
        openCommonModal({
          modalType: 'confirm',
          content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
          yesCallback: () => {
            openLoading(true);

            const item = infoRef.current.getMpItem();
            const param = {
              ...item,
              ...defaultParam,
            };
            setMpItem(param);

            saveMpItem(param)
              .then((res) => {
                openMessageBar({
                  type: res.successOrNot === 'Y' ? 'confirm' : 'error',
                  content:
                    res.successOrNot === 'Y'
                      ? t('com.label.저장되었습니다.', '저장되었습니다.')
                      : t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
                });
                if (locationState?.mode === ManagementMode.CREATE) {
                  menuContext.closeCurrentTab(menuContext);
                  setTimeout(() => {
                    navigate(`/mp/item-management`, {
                      replace: true,
                      state: {
                        upprMnuUrl: '/mp/item',
                        mode: ManagementMode.MODIFY,
                        itemId: param.itemId,
                        mnuId: `${location.pathname}/${ManagementMode.MODIFY}`,
                        mnuNm: t('mp.label.Item 수정', 'Item 수정'),
                      },
                    });
                  }, 100);
                } else {
                  setLocationState((prev) => ({
                    ...prev,
                    mode: ManagementMode.MODIFY,
                    itemId: param.itemId,
                  }));
                  loadMpItem(param.itemId, true);
                }
              })
              .finally(() => openLoading(false));
          },
        });
      } else {
        if (!otherRef.current.validate()) {
          return;
        }

        const param = {
          ...defaultParam,
          itemTpCd: tabId,
        };

        openCommonModal({
          modalType: 'confirm',
          content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
          yesCallback: () => {
            saveMpResult(param, otherRef.current.getRowData()).then((res) => {
              openMessageBar({
                type: res.successOrNot === 'Y' ? 'confirm' : 'error',
                content:
                  res.successOrNot === 'Y'
                    ? t('com.label.저장되었습니다.', '저장되었습니다.')
                    : t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
              });
              setLocationState((prev) => ({
                ...prev,
                mode: ManagementMode.MODIFY,
                itemId: param.itemId,
              }));
              loadMpItem(param.itemId);
              otherRef.current.refresh();
            });
          },
        });
      }
    }
  };

  const handleChangeStat = async (stat) => {
    // 특화로직 추가
    let valid = true;
    let msg = '';
    if (!mpItem?.itemId || !mpItem?.itemTpChoCd) {
      openMessageBar({
        type: 'error',
        content:
          t('com.label.잘못된 접근입니다.', '잘못된 접근입니다.') +
          <br /> +
          t('com.label.새로고침 후 다시 시도해 주세요.', '새로고침 후 다시 시도해 주세요.'),
      });
      return;
    }

    // MpItemInfo Validation
    if (!infoRef.current.validateItemStatCd(stat)) {
      return;
    } else {
      if (stat === TapButtonType.ButtonMpReview) {
        msg = t('mp.msg.검토중 상태로 변경하시겠습니까?', '검토중 상태로 변경하시겠습니까?');
      } else if (stat === TapButtonType.ButtonMpVal) {
        msg = t('mp.msg.검증중 상태로 변경하시겠습니까?', '검증중 상태로 변경하시겠습니까?');
      } else if (stat === TapButtonType.ButtonMpValComp) {
        msg = t('mp.msg.검증완료 상태로 변경하시겠습니까?', '검증완료 상태로 변경하시겠습니까?');
      } else if (stat === TapButtonType.ButtonHeUnitVal) {
        msg = t(
          'mp.msg.대표호기검증 상태로 변경하시겠습니까?',
          '대표호기검증 상태로 변경하시겠습니까?'
        );
      } else if (stat === TapButtonType.ButtonHeAction) {
        msg = t(
          'mp.msg.수평전개 실행 상태로 변경하시겠습니까?',
          '수평전개 실행 상태로 변경하시겠습니까?'
        );
      } else if (stat === TapButtonType.ButtonHeComp) {
        await findMpResultsForValid(mpItem?.itemId, mpItem?.itemTpChoCd).then((result) => {
          const totalSize = result.length;
          // [24.05.14] 수평전개 탭은 상태가 수평전개실행/수평전개완료 일 때만 노출되도록 변경되어 수평전개완료로 넘어갈 때 담당자 지정여부 확인
          const mgrSize = result.filter((item) => item.mgrId).length;
          if (mgrSize != totalSize) {
            openMessageBar({
              type: 'error',
              content: t(
                'mp.msg.수평전개 탭에서 담당자를 지정해주세요.',
                '수평전개 탭에서 담당자를 지정해주세요.'
              ),
            });
            changeCurrentTabIndex(mpItem?.itemTpChoCd);
            valid = false;
          }
          if (valid) {
            const compSize = result.filter(
              (item) => item.statusCd === 'COMPLETE' || item.statusCd === 'NO_TGT'
            ).length;
            if (compSize != totalSize) {
              openMessageBar({
                type: 'error',
                content: t(
                  'mp.msg.Project 상태가 완료 되어있는지 확인해주세요.',
                  'Project 상태가 완료 되어있는지 확인해주세요.'
                ),
              });
              changeCurrentTabIndex(mpItem?.itemTpChoCd);
              valid = false;
            }
          }
        });
        msg = t(
          'mp.msg.수평전개 완료 상태로 변경하시겠습니까?',
          '수평전개 완료 상태로 변경하시겠습니까?'
        );
      } else if (stat === TapButtonType.ButtonNiDevComp) {
        msg = t('mp.msg.개발완료 상태로 변경하시겠습니까?', '개발완료 상태로 변경하시겠습니까?');
      } else if (stat === TapButtonType.ButtonMpHeReview) {
        msg = t('mp.msg.검토중 상태로 변경하시겠습니까?', '검토중 상태로 변경하시겠습니까?');
      } else if (stat === TapButtonType.ButtonMpHeVal) {
        msg = t('mp.msg.검증중 상태로 변경하시겠습니까?', '검증중 상태로 변경하시겠습니까?');
      } else if (stat === TapButtonType.ButtonMpHeValComp) {
        msg = t('mp.msg.검증완료 상태로 변경하시겠습니까?', '검증완료 상태로 변경하시겠습니까?');
      } else if (stat === TapButtonType.ButtonMpHeAction) {
        msg = t(
          'mp.msg.수평전개 실행 상태로 변경하시겠습니까?',
          '수평전개 실행 상태로 변경하시겠습니까?'
        );
      } else if (stat === TapButtonType.ButtonMpHeComp) {
        await findMpResultsForValid(mpItem?.itemId, mpItem?.itemTpChoCd).then((result) => {
          const totalSize = result.length;
          // [24.05.14] 수평전개 탭은 상태가 수평전개실행/수평전개완료 일 때만 노출되도록 변경되어 수평전개완료로 넘어갈 때 담당자 지정여부 확인
          const mgrSize = result.filter((item) => item.mgrId).length;
          if (mgrSize != totalSize) {
            openMessageBar({
              type: 'error',
              content: t(
                'mp.msg.수평전개 탭에서 담당자를 지정해주세요.',
                '수평전개 탭에서 담당자를 지정해주세요.'
              ),
            });
            changeCurrentTabIndex(mpItem?.itemTpChoCd);
            valid = false;
          }
          if (valid) {
            const compSize = result.filter(
              (item) => item.statusCd === 'COMPLETE' || item.statusCd === 'NO_TGT'
            ).length;
            if (compSize != totalSize) {
              openMessageBar({
                type: 'error',
                content: t(
                  'mp.msg.Project 상태가 완료 되어있는지 확인해주세요.',
                  'Project 상태가 완료 되어있는지 확인해주세요.'
                ),
              });
              changeCurrentTabIndex(mpItem?.itemTpChoCd);
              valid = false;
            }
          }
        });

        msg = t(
          'mp.msg.수평전개 완료 상태로 변경하시겠습니까?',
          '수평전개 완료 상태로 변경하시겠습니까?'
        );
      } else {
        const dropIncludes = [
          TapButtonType.ButtonNiDrop,
          TapButtonType.ButtonMpDrop,
          TapButtonType.ButtonMpHeDrop,
          TapButtonType.ButtonHeDrop,
        ] as string[];

        if (dropIncludes.includes(stat)) {
          msg = t('mp.msg.드랍하시겠습니까?', '드랍하시겠습니까?');
        }
      }

      if (!valid) {
        return;
      }
    }

    openCommonModal({
      modalType: 'confirm',
      content: msg,
      yesCallback: () => {
        openLoading(true);

        const param = {
          itemStatCd: stat,
          itemId: mpItem?.itemId,
        };

        saveMpItemStat(param)
          .then((res) => {
            setMpItem((prev) => ({
              ...prev,
              ...param,
            }));

            openMessageBar({
              type: res.successOrNot === 'Y' ? 'confirm' : 'error',
              content:
                res.successOrNot === 'Y'
                  ? t('com.label.저장되었습니다.', '저장되었습니다.')
                  : t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            if (locationState?.mode === ManagementMode.CREATE) {
              menuContext.closeCurrentTab(menuContext);
              setTimeout(() => {
                navigate(`/mp/item-management`, {
                  replace: true,
                  state: {
                    upprMnuUrl: '/mp/item',
                    mode: ManagementMode.MODIFY,
                    itemId: param.itemId,
                    mnuId: `${location.pathname}/${ManagementMode.MODIFY}`,
                    mnuNm: t('mp.label.Item 수정', 'Item 수정'),
                  },
                });
              }, 100);
            } else {
              setLocationState((prev) => ({
                ...prev,
                mode: ManagementMode.MODIFY,
                itemId: param.itemId,
              }));
              loadMpItem(param.itemId, true);
            }
          })
          .finally(() => openLoading(false));
      },
    });
  };

  const handleOpenEmpPopup = () => {
    setEmpPopupCondition({
      fieldId: 'reqMgrNm',
      initParam: displayCondition?.reqMgrNm || '',
    });
    setOpenElmEmpPopup(true);
  };

  return (
    <>
      <TableContainer css={tb.table} style={{ marginBottom: '24px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className="dot">{t('mp.label.Item 명', 'Item 명')}</span>
              </TableCell>
              <TableCell colSpan={3}>
                <CustomInputWithSearch
                  name="itemNm"
                  placeholder={String(
                    t('mp.msg.Item 명을 입력해 주세요.', 'Item 명을 입력해 주세요.')
                  )}
                  value={mpItem?.itemNm}
                  onChange={handleItemChange}
                  isError={errors?.itemNm}
                  msgError={String(
                    t('mp.msg.Item 명을 입력해 주세요.', 'Item 명을 입력해 주세요.')
                  )}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{t('mp.label.기술 제안자', '기술 제안자')}</span>
              </TableCell>
              <TableCell colSpan={3}>
                <CustomInputWithSearch
                  name="reqMgrNm"
                  className="find"
                  placeholder={String(
                    t('mp.msg.기술 제안자를 입력해 주세요.', '기술 제안자를 입력해 주세요.')
                  )}
                  value={displayCondition.reqMgrNm}
                  onChange={handleDisplayConditionChange}
                  onSearchClick={() => {
                    handleOpenEmpPopup();
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      handleOpenEmpPopup();
                    }
                  }}
                  isError={errors?.reqMgrId}
                  msgError={String(
                    t('mp.msg.기술 제안자를 선택해 주세요.', '기술 제안자를 선택해 주세요.')
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('mp.label.Item 영문명', 'Item 영문명')}</span>
              </TableCell>
              <TableCell colSpan={3}>
                <CustomInputWithSearch
                  name="itemEngNm"
                  placeholder={String(
                    t('mp.msg.Item 영문명을 입력해 주세요.', 'Item 영문명을 입력해 주세요.')
                  )}
                  value={mpItem?.itemEngNm}
                  onChange={handleItemChange}
                  isError={errors?.itemEngNm}
                  msgError={String(
                    t('mp.msg.Item 영문명을 입력해 주세요.', 'Item 영문명을 입력해 주세요.')
                  )}
                />
              </TableCell>
              <TableCell>{t('mp.label.Item 번호', 'Item 번호')}</TableCell>
              <TableCell colSpan={3}>{mpItem?.itemId}</TableCell>
            </TableRow>
            {ManagementMode.MODIFY === locationState?.mode && (
              <TableRow>
                <TableCell>{t('com.label.등록자', '등록자')}</TableCell>
                <TableCell>{mpItem?.dataInsUserNm}</TableCell>
                <TableCell>{t('com.label.등록일', '등록일')}</TableCell>
                <TableCell>{mpItem?.dataInsDtm}</TableCell>
                {/*{dayjs(mpItem?.dataInsDtm).format('YYYY.MM.DD')}*/}

                <TableCell>{t('com.label.수정자', '수정자')}</TableCell>
                <TableCell>{mpItem?.dataUpdUserNm}</TableCell>
                <TableCell>{t('com.label.수정일', '수정일')}</TableCell>
                <TableCell>{dayjs(mpItem?.dataUpdDtm).format('YYYY.MM.DD')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {mpItem?.itemId && (
        <div>
          <div css={tabs.wrap}>
            <Tabs
              value={currentTabIndex}
              onChange={(e, newValue) => setCurrentTabIndex(newValue)}
              css={tabs.tab}
            >
              {tabMenus.map((o, idx) => (
                <Tab
                  key={o.id}
                  label={o.name}
                  id={`mp-tab-${idx}`}
                  aria-controls={`mp-tabpanel-${idx}`}
                />
              ))}
            </Tabs>
            <div css={tabs.btn}>
              {tabButtons.map((button) => {
                return renderTabButtons(button);
              })}
            </div>
          </div>
          <TabPanel value={currentTabIndex} index={currentTabIndex}>
            {currentTabIndex === 0 ? (
              <MpItemInfoContent
                ref={infoRef}
                defaultMpItem={mpItem}
                mode={locationState?.mode}
                isCopy={!!locationState?.copyTarget}
              />
            ) : (
              <MpItemOtherContent
                ref={otherRef}
                itemTpCd={tabMenus[currentTabIndex].id}
                itemId={mpItem.itemId}
                mpClsfCds={mpItem.mpClsfCds}
                isRefresh={isRefresh}
                condition={locationState}
                onChangeRefresh={() => {
                  if (isRefresh) {
                    setRefresh(false);
                  }
                }}
              />
            )}
          </TabPanel>
        </div>
      )}

      {isOpenElmEmpPopup && (
        <ElmEmpPopup
          setUserData={setUserData}
          setPopup={setOpenElmEmpPopup}
          initParam={empPopupCondition.initParam}
          fieldId={empPopupCondition.fieldId}
          close={() => setOpenElmEmpPopup(false)}
        />
      )}
    </>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`mp-tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}
export default MpItemManagementPage;
