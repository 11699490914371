/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import useSessionStore from 'stores/useSessionStore';
import { useContext, useEffect } from 'react';
import { MenuContextType } from 'models/admin/Menu';
import { MenuContext } from 'App';
import { CommonText, NavTop } from 'ui/theme/Color';
import { Link, useNavigate } from 'react-router-dom';
import { Menu } from 'models/admin/Menu';
import { menuType } from './SideMenuBar';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const nest = (menuData: Menu[], mnuId = '000000', link = 'upprMnuId') =>
  menuData
    .filter((item) => item[link] === mnuId)
    .map((item) => ({ ...item, children: nest(menuData, item.mnuId) }));

export const HeadLinkList = () => {
  const { headerMenus } = useSessionStore();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const navigate = useNavigate();
  const { menus } = useSessionStore();
  const { t } = useTranslation();

  const handleMenu = () => {
    let url: string | undefined = '';
    if (menus && menuContext.selectedHeaderMenu) {
      const depth1Menus: Menu[] = menus.filter(
        (item) => item.upprMnuId === menuContext.selectedHeaderMenu
      );

      const selectedheaderMenuInfo = headerMenus.filter(
        (item) => item.mnuId === menuContext.selectedHeaderMenu
      );

      const newSideMenuList: menuType[] = [];
      depth1Menus.forEach((item) => {
        const tree = nest(menus, item.mnuId);
        newSideMenuList.push({
          menuInfo: item,
          children: tree,
        });
      });

      if (newSideMenuList && newSideMenuList.length > 0) {
        const sidebarMnuId = selectedheaderMenuInfo[0]?.mnuOptValCtn2;
        if (sidebarMnuId === null || sidebarMnuId === undefined) {
          url =
            newSideMenuList[0].children.length > 0
              ? '/' + newSideMenuList[0]?.children[0]?.mnuUrl
              : '/' + newSideMenuList[0].menuInfo.mnuUrl;

          if (
            _.isEmpty(url) ||
            url === '/null' ||
            url === '/notFound' ||
            newSideMenuList[0].menuInfo.mnuOptValCtn1 === 'LINK'
          ) {
            let index = 0;
            newSideMenuList.forEach((item, i) => {
              if (item.children.length > 0) {
                index = i;
              }
              if (index > 0) {
                url = newSideMenuList[index]?.children[0]?.mnuUrl;
              }
            });
          }
        } else if (sidebarMnuId !== null && sidebarMnuId !== undefined) {
          const sidebarUrl = menus.find((menu) => menu.mnuId === sidebarMnuId);
          url = '/' + sidebarUrl?.mnuUrl;
        }
      }
    }

    return url;
  };

  useEffect(() => {
    if (menuContext.clickedByHeaderMenu) {
      navigate(handleMenu());
    }
  }, [menuContext.selectedHeaderMenu]);

  return (
    <div css={st.root}>
      <div css={st.links}>
        {headerMenus &&
          headerMenus.map((menu) => (
            <Link
              css={headLinkStyle.root}
              className={menu.mnuOptValCtn1 === 'TO-BE' ? 'disabled' : ''}
              onClick={() =>
                menuContext.handleMenuChange({
                  ...menuContext,
                  selectedHeaderMenu: menu.mnuId,
                  clickedByHeaderMenu: true,
                })
              }
              key={menu.mnuId}
              to={handleMenu()}
            >
              <Typography
                fontSize={'14px'}
                color={
                  menu.mnuId === menuContext.selectedHeaderMenu
                    ? CommonText.Basic
                    : CommonText.Lighter
                }
                fontWeight={'700'}
              >
                {menu.mnuNm}
              </Typography>
              <div css={headLinkStyle.line(menu.mnuId === menuContext.selectedHeaderMenu)}></div>
            </Link>
          ))}
      </div>
    </div>
  );
};

const st = {
  root: css`
    height: 100%;
    display: flex;
    align-items: center;
  `,
  links: css`
    height: 100%;
    display: flex;
    gap: 50px;
    a.disabled {
      color: ${CommonText.Light};
      opacity: 0.4;
      font-weight: 400;
    }
  `,
};

const headLinkStyle = {
  root: css`
    position: relative;
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,
  line: (isClicked: boolean) => css`
    position: absolute;
    display: ${isClicked ? 'block' : 'none'};
    width: 120%;
    height: 3px;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${NavTop.BorderSelected};
    transition: 3s;
  `,
};
