/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import {
  MpItem,
  MpItemAtchFileTpCd,
  MpItemBookmarkRequest,
  MpItemCondition,
  MpItemCopyRequest,
} from 'models/mp/MpItem';
import { addBookmarkItem, copyMpItem, downloadExcelMpItems, findMpItems } from 'apis/mp/MpItem';
import FileUploadPopUp from '../common/components/FileUploadPopUp';
import { useMessageBar } from 'hooks/useMessageBar';
import { BizName, ManagementMode } from 'models/common/Common';
import MpMyBookmarkModal from 'pages/mp/MpMyBookmarkModal';
import { getExcelFileName } from 'utils/ExcelUtil';
import MPCommonSearch from './base/MPCommonSearch';
import MpItemCopModal from 'components/modals/mp/MpItemCopModal';
import { hasRole } from 'utils/SessionUtil';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import CustomGrid from 'components/grids/CustomGrid';
import { downloadExcelTemplatesPost } from 'apis/common/Excel';
import { getAtchFileGrId } from 'apis/admin/FileUpload';
import { useLoading } from 'components/process/Loading';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { BmkAtcTpCd } from 'models/common/Bookmark';
import AutoSendEmailModal from 'components/modals/common/AutoSendEmailModal';
import { CommonUtil } from 'utils/CommonUtil';

const MpItemPage = () => {
  const { openLoading } = useLoading();
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const navigate = useNavigate();
  const [mpItemCondition, setMpItemCondition] = useState<MpItemCondition>({
    startDataInsDtm: dayjs().add(-2, 'year').format('YYYY.MM.DD'),
    endDataInsDtm: dayjs().format('YYYY.MM.DD'),
  });
  const gridRef = useRef<any>();
  const [rowData, setRowData] = useState<MpItem[]>([]);
  const [checkedItems, setCheckedItems] = useState<MpItem[]>([]);
  const [itemId, setItemId] = useState<any>();
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});
  const [mpMyBookmarkModalOpen, setMpMyBookmarkModalOpen] = useState<boolean>(false);
  const [isOpenMpItemCopModal, setOpenMpItemCopModal] = useState<boolean>(false);
  const [isOpenEmailModal, setOpenEmailModal] = useState<boolean>(false);
  const [hasAuth, setHasAuth] = useState<boolean>(false);

  const [bmkGrId, setBmkGrId] = useState<string>();
  const [mpItemCopyRequest, setMpItemCopyRequest] = useState<MpItemCopyRequest>({ mpItems: [] });
  const [mpItemBookmarkRequest, setMpItemBookmarkRequest] = useState<MpItemBookmarkRequest>({
    mpItems: [],
  });

  const layoutDefinition = [
    {
      header: String(t('mp.grid.Item', 'Item')),
      columns: [
        {
          binding: 'itemId',
          header: String(t('mp.grid.번호', '번호')),
          width: 105,
          align: 'center',
          cellTemplate: CellMaker.makeLink({
            text: '${item.itemId}',
            click: (e, ctx) => {
              if (ctx && Object.keys(ctx).includes('value')) {
                moveToModify(ctx['value']);
              }
            },
          }),
        },
        {
          binding: 'itemNm',
          header: String(t('mp.grid.명', '명')),
          width: 200,
          cellTemplate: (params) => CommonUtil.unescapeHtml(params.value),
        },
        {
          binding: 'itemEngNm',
          header: String(t('mp.grid.영문명', '영문명')),
          width: 200,
          cellTemplate: (params) => CommonUtil.unescapeHtml(params.value),
        },
      ],
    },
    {
      binding: 'mpClsfCdNm',
      header: String(t('mp.grid.MP 분류', 'MP 분류')),
      align: 'center',
      width: 140,
    },
    {
      binding: 'procCdNm',
      header: String(t('mp.grid.공정', '공정')),
      align: 'center',
      width: 110,
    },
    {
      header: String(t('mp.grid.설비분류', '설비분류')),
      align: 'center',
      columns: [
        {
          binding: 'eqpGr',
          header: String(t('com.label.설비군', '설비군')),
          width: 150,
        },
        {
          binding: 'eqpClsfNm',
          header: String(t('mp.grid.설비분류체계', '설비분류체계')),
          width: 170,
        },
        {
          binding: 'eqpLv1Nm',
          header: String(t('mp.grid.Main', 'Main')),
          width: 150,
        },
        {
          binding: 'eqpLv2Nm',
          header: String(t('mp.grid.Machine', 'Machine')),
          width: 150,
        },
        {
          binding: 'eqpLv3Nm',
          header: String(t('mp.grid.Unit', 'Unit')),
          width: 150,
        },
      ],
    },
    {
      header: String(t('mp.grid.개선목적', '개선목적')),
      align: 'center',
      columns: [
        {
          binding: 'iprvClsfLv1CdNm',
          header: String(t('mp.grid.Level 1', 'Level 1')),
          width: 130,
        },
        {
          binding: 'iprvClsfLv2CdNm',
          header: String(t('mp.grid.Level 2', 'Level 2')),
          width: 130,
        },
      ],
    },
    {
      binding: 'wkDivsCdNm',
      align: 'center',
      header: String(t('mp.grid.작업구분', '작업구분')),
      width: 100,
    },
    {
      binding: 'copCdNm',
      header: String(t('mp.grid.대상법인', '대상법인')),
      width: 200,
      cssClass: 'WijmoFind',
      cellTemplate: (ctx) => {
        return `<span>${ctx.value || ''}</span>
              <Button id="btnCompany" />`;
      },
    },
    {
      binding: 'isuDesc',
      header: String(t('mp.grid.Issue사항/개선목적', 'Issue사항/개선목적')),
      width: 200,
    },
    {
      binding: 'iprvCtn',
      header: String(t('mp.grid.개조/개선 항목', '개조/개선 항목')),
      width: 150,
    },
    {
      binding: 'rmk',
      header: String(t('mp.grid.비고', '비고')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'itemStatCdNm',
      header: String(t('com.label.상태', '상태')),
      align: 'center',
      width: 150,
    },
    {
      header: String(t('mp.grid.MP 유형', 'MP 유형')),
      align: 'center',
      columns: [
        {
          binding: 'mpYn',
          header: String(t('mp.grid.설비변경점', '설비변경점')),
          width: 120,
          align: 'center',
        },
        {
          binding: 'heYn',
          header: String(t('mp.grid.수평전개', '수평전개')),
          width: 120,
          align: 'center',
        },
        {
          binding: 'niYn',
          header: String(t('mp.grid.신규개선', '신규개선')),
          width: 120,
          align: 'center',
        },
      ],
    },
    {
      binding: 'reqMgrNm',
      header: String(t('mp.grid.기술 제안자', '기술 제안자')),
      width: 110,
      align: 'center',
    },
    {
      binding: 'atchFileCnt',
      header: String(t('mp.grid.첨부', '첨부')),
      width: 80,
      align: 'center',
      cellTemplate: (params) =>
        `<button id="fileAttach" class='fileDiv'>
              ${(params.value || 0).toLocaleString()}
            </button>
          `,
    },
    {
      binding: 'mpItemRptAtchFileCnt',
      header: String(t('mp.grid.개조/개선 보고서', '개조/개선 보고서')),
      width: 140,
      align: 'center',
      cellTemplate: (params) => `<button id="mpItemRptAtchFile" class='fileDiv'>
              ${params.value || 0}
            </button> 
        `,
    },
    {
      binding: 'vaidEvlRptFileCnt',
      header: String(t('mp.grid.유효성 평가보고서', '유효성 평가보고서')),
      width: 140,
      align: 'center',
      cellTemplate: (params) => `<button id="vaidEvlRptAtchFile" class='fileDiv'>
              ${params.value || 0} 
            </button>
        `,
    },
    {
      binding: 'scmmMintAtchFileCnt',
      header: String(t('mp.grid.회의록', '회의록')),
      width: 80,
      align: 'center',
      cellTemplate: (params) => `<button id="scmmMintAtchFile" class='fileDiv'>
              ${params.value || 0}
            </button>
        `,
    },
    {
      binding: 'm4ChgYn',
      header: String(t('mp.grid.4M 여부', '4M 여부')),
      width: 90,
      align: 'center',
    },
    {
      binding: 'spshRvwYn',
      header: String(t('mp.grid.표준사양서검토여부', '표준사양서검토여부')),
      width: 160,
      align: 'center',
    },
    {
      binding: 'specSheetAtchFileCnt',
      header: String(t('mp.grid.표준사양서', '표준사양서')),
      width: 100,
      align: 'center',
      cellTemplate: (params) => `<button id="specFile" class='fileDiv'>
              ${params.value || 0}
            </button>
        `,
    },
    {
      binding: 'dataInsUserNm',
      header: String(t('com.label.등록자', '등록자')),
      width: 100,
      align: 'center',
    },
    {
      binding: 'dataInsDtm',
      header: String(t('com.label.등록일', '등록일')),
      width: 120,
      align: 'center',
    },
    {
      binding: 'atchFileGrId',
      visible: false,
    },
  ];

  const onInitialized = (grid) => {
    gridRef.current = grid;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return;

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const item = grid.rows[ht.row].dataItem;

      setCheckedItems(grid.selectedItems);

      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnCompany':
            if (item.itemId) {
              setItemId(item.itemId);
              setOpenMpItemCopModal(true);
            }
            break;
          case 'fileAttach':
            if (item.atchFileCnt > 0) {
              handleOpenFileDownload(item.atchFileGrId, MpItemAtchFileTpCd.MP_ITEM);
            }
            break;
          case 'mpItemRptAtchFile':
            if (item.mpItemRptAtchFileCnt > 0) {
              handleOpenFileDownload(item.atchFileGrId, MpItemAtchFileTpCd.MP_ITEM_RPT);
            }
            break;
          case 'vaidEvlRptAtchFile':
            if (item.vaidEvlRptFileCnt > 0) {
              handleOpenFileDownload(item.atchFileGrId, MpItemAtchFileTpCd.VAID_EVL_RPT);
            }
            break;
          case 'scmmMintAtchFile':
            if (item.scmmMintAtchFileCnt > 0) {
              handleOpenFileDownload(item.atchFileGrId, MpItemAtchFileTpCd.SCMM_MINT);
            }
            break;
          case 'specFile':
            if (item.atchFileCnt > 0) {
              handleOpenFileDownload(item.atchFileGrId, MpItemAtchFileTpCd.SPEC_SHEET);
            }
            break;
        }
      }
      grid.refresh();
    });
  };

  useEffect(() => {
    // mp 권한체크
    if (hasRole('MP_MANAGER') || hasRole('ADM')) {
      setHasAuth(true);
    }
  }, []);

  const handleSearch = async (condition: MpItemCondition) => {
    setCheckedItems([]);
    setMpItemCondition(condition);
    const response = await findMpItems(condition);
    if (response) {
      const rowData = response || [];
      setRowData(rowData);
    }
  };

  const handleOpenFileDownload = (atchFileGrId: string, atchFileTpCd: string) => {
    setFileUploadModalCondition({
      atchFileGrId: atchFileGrId,
      atchFileTpCd: atchFileTpCd,
      optValCtn1: 'TB_ELM_MP_ITEM_M',
      bizName: 'mp',
      downloadOnly: true,
    });
    setOpenFileUploadModal(true);
  };

  const moveToModify = (itemId: string) => {
    navigate(`/mp/item-management`, {
      state: {
        upprMnuUrl: location.pathname,
        mode: ManagementMode.MODIFY,
        itemId: itemId,
        mnuId: `${location.pathname}/${ManagementMode.MODIFY}`,
        mnuNm: t('mp.label.Item 수정', 'Item 수정'),
      },
    });
  };

  const handleDownloadExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('mp.label.Item', 'Item');
    book.saveAsync(
      getExcelFileName(t('mp.label.설비변경점_수평전개_Item', '설비변경점_수평전개_Item'))
    );
  };

  const handleCopyItemRow = async (e) => {
    if (!checkedItems.length) {
      openMessageBar({
        type: 'error',
        content: t('com.label.복사할 행을 선택해 주세요.', '복사할 행을 선택해 주세요.'),
      });
      return;
    } else if (checkedItems.length > 1) {
      openMessageBar({
        type: 'error',
        content: t(
          'com.label.복사할 행은 하나만 선택해 주세요.',
          '복사할 행은 하나만 선택해 주세요.'
        ),
      });
      return;
    }

    // 복사 관련 변경사항 생길지 몰라 주석
    // const response = await getAtchFileGrId();
    const rows = checkedItems?.map((row) => {
      if (row?.atchFileGrId) {
        const uploadRow =
          {
            ...row,
            atchFileGrId: '',
            atchFileCnt: 0,
            minuteAtchFileCnt: 0,
            mpItemRptAtchFileCnt: 0,
            scmmMintAtchFileCnt: 0,
            specSheetAtchFileCnt: 0,
            vaidEvlRptFileCnt: 0,
          } || [];
        return uploadRow;
      }
      return row;
    });

    navigate('/mp/item-management/new', {
      state: {
        upprMnuUrl: location.pathname,
        mode: ManagementMode.CREATE,
        mnuId: `${location.pathname}/${ManagementMode.CREATE}`,
        mnuNm: t('mp.label.Item 등록', 'Item 등록'),
        copyTarget: rows[0],
      },
    });
  };

  const handleAddBookmarkItemRow = async (e) => {
    const rows = checkedItems || [];

    if (!rows.length) {
      openMessageBar({
        type: 'error',
        content: t('ip.msg.item이 선택되지 않았습니다.', 'item이 선택되지 않았습니다.'),
      });
      return;
    } else if (!bmkGrId) {
      openMessageBar({
        type: 'error',
        content: t(
          'mp.msg.나의 관심항목이 선택되지 않았습니다.',
          '나의 관심항목이 선택되지 않았습니다.'
        ),
      });
      return;
    }

    addBookmarkItem({
      ...mpItemBookmarkRequest,
      bmkGrId: bmkGrId,
      mpItems: rows,
    }).then((result) => {
      openMessageBar({
        type: result?.successOrNot === 'Y' ? 'confirm' : 'error',
        content:
          result?.successOrNot === 'Y'
            ? t('com.label.저장되었습니다.', '저장되었습니다.')
            : t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
      });
      handleSearch({ ...mpItemCondition, bmkGrId: bmkGrId });
    });
  };

  const downloadExcel = async (templateType) => {
    openLoading(true);
    const rows = rowData || [];

    const ids = (rows || []).reduce((acc, cur) => [...acc, cur.itemId as string], [] as string[]);
    downloadExcelTemplatesPost('TPL_MP_LIST', '', ids).finally(() => openLoading(false));
  };

  return (
    <>
      <MPCommonSearch
        type={'item'}
        changeBookmark={(bmkGrId) => setBmkGrId(bmkGrId)}
        onHandleSearch={handleSearch}
      />
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('mp.grid.설비변경점/수평전개 Item', '설비변경점/수평전개 Item')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {/*
          <span className="total">
            전체: <span>{(totalCount || 0).toLocaleString()}</span>건, 수평전개 검토중{' '}
            <span>00</span>
            건,검증중 <span>00</span>건, 검증완료 <span>00</span>건, 완료 <span>00</span>건
          </span>
          */}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="copyRow"
            disableRipple
            onClick={(e) => handleCopyItemRow(e)}
          >
            {t('com.button.복사', '복사')}
          </Button>
          <Button
            css={IconButton.button}
            className="bookmark"
            onClick={(e) => handleAddBookmarkItemRow(e)}
            disableRipple
          >
            {t('com.button.관심항목추가', '관심항목추가')}
          </Button>
          <Button
            css={IconButton.button}
            className="bookmark"
            disableRipple
            onClick={(e) => {
              setMpMyBookmarkModalOpen(true);
            }}
          >
            {t('mp.button.나의관심항목등록', '나의관심항목등록')}
          </Button>
          <Button
            css={IconButton.button}
            className="user"
            onClick={() => {
              navigate('/mp/target');
            }}
            disableRipple
          >
            {t('mp.button.MP 대상/담당자', 'MP 대상/담당자')}
          </Button>
          <Button
            css={IconButton.button}
            className="mail"
            onClick={() => setOpenEmailModal(true)}
            disableRipple
          >
            {t('com.button.자동메일설정', '자동메일설정')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={() => downloadExcel('TPL_MP_LIST')}
            disableRipple
          >
            {t('com.button.템플릿 다운로드', '템플릿 다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleDownloadExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch(mpItemCondition)}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={510}
        isReadOnly={true}
        initialized={onInitialized}
        onChangeCheckedItem={(items) => setCheckedItems(items)}
      />
      <GlobalBtnGroup>
        {hasAuth && (
          <Button
            css={IconButton.button}
            className="write"
            disableRipple
            onClick={(e) => {
              navigate('/mp/excel-management', {
                replace: true,
                state: {
                  upprMnuUrl: location.pathname,
                  mnuId: location.pathname,
                  mnuNm: `MP List Upload`,
                  mode: ManagementMode.CREATE_EXCEL,
                },
              });
            }}
          >
            {t('com.button.엑셀', '엑셀 신규')}
          </Button>
        )}
        <Button
          css={IconButton.button}
          className="outline write"
          onClick={() => {
            navigate('/mp/item-management/new', {
              state: {
                upprMnuUrl: location.pathname,
                mode: ManagementMode.CREATE,
                mnuId: `${location.pathname}/${ManagementMode.CREATE}`,
                mnuNm: t('mp.label.Item 등록', 'Item 등록'),
              },
            });
          }}
          disableRipple
        >
          {t('com.button.신규', '신규')}
        </Button>
        <Button
          css={IconButton.button}
          className="outline write"
          onClick={() => {
            if (checkedItems && checkedItems.length > 0) {
              if (checkedItems.length > 1) {
                openMessageBar({
                  type: 'error',
                  content: t(
                    'mp.msg.수정할 대상을 1건만 선택해 주세요.',
                    '수정할 대상을 1건만 선택해 주세요.'
                  ),
                });
                return;
              }
              if (checkedItems[0].itemId) {
                moveToModify(checkedItems[0].itemId);
              }
            } else {
              openMessageBar({
                type: 'error',
                content: t('mp.msg.수정할 대상을 선택해 주세요.', '수정할 대상을 선택해 주세요.'),
              });
            }
          }}
          disableRipple
        >
          {t('com.button.수정', '수정')}
        </Button>
      </GlobalBtnGroup>

      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          initParam={{
            atchFileGrId: fileUploadModalCondition.atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: fileUploadModalCondition.bizName,
          }}
          downloadOnly={true}
          onCallback={(atchFileGrId, fileCount) => {
            if (fileUploadModalCondition.target) {
              fileUploadModalCondition.target.node?.setDataValue(
                fileUploadModalCondition.target.column?.getColId(),
                fileCount
              );
            }
            setOpenFileUploadModal(false);
          }}
        />
      )}
      {mpMyBookmarkModalOpen && (
        <MpMyBookmarkModal
          open={mpMyBookmarkModalOpen}
          close={() => setMpMyBookmarkModalOpen(false)}
        />
      )}
      {isOpenMpItemCopModal && (
        <MpItemCopModal
          open={isOpenMpItemCopModal}
          close={() => setOpenMpItemCopModal(false)}
          itemId={itemId}
          isReadOnly={true}
        />
      )}
      {isOpenEmailModal && (
        <AutoSendEmailModal
          open={isOpenEmailModal}
          close={() => setOpenEmailModal(false)}
          condition={{
            bizName: BizName.MP, // 이메일업무구분코드
            bmkAtcTpCd: BmkAtcTpCd.MP_LIST, //즐겨찾기항목유형코드
          }}
        />
      )}
    </>
  );
};

export default MpItemPage;
