import DOMPurify from 'dompurify';
export const CommonUtil = {
  /**
   * [ELM 공통 - HTML 엔티티 치환 함수]
   *
   * Html String을 unescapeHtml 처리하는 함수.
   **/
  unescapeHtml(htmlString) {
    return String(htmlString)
      .replaceAll('&#59;', ':')
      .replaceAll('&#92;', '\\')
      .replaceAll('&#47;', '/')
      .replaceAll('&#34;', '"')
      .replaceAll('&#39;', "'")
      .replaceAll('&#41;', ')')
      .replaceAll('&#40;', '(')
      .replaceAll('&#60;', '<')
      .replaceAll('&lt;', '<')
      .replaceAll('&#62;', '>')
      .replaceAll('&gt;', '>')
      .replaceAll('&#35;', '#')
      .replaceAll('&amp;', '&');
  },

  /**
   * [ELM 공통 - HTML 엔티티 치환 함수]
   *
   * Html String을 escapeHtml 처리하는 함수.
   **/
  escapeHtml(htmlString) {
    return htmlString?.replace(/[&<>()#]/g, (match) => {
      switch (match) {
        case '&':
          return '&amp;';
        case '<':
          return '&lt;';
        case '>':
          return '&gt;';
        case '(':
          return '&#40;';
        case ')':
          return '&#41;';
        case '#':
          return '&#35;';
        default:
          return match;
      }
    });
  },
  /**
   * [ELM 공통 - XSS 공격 방지용 함수]
   *
   * 공지사항 같은 경우 HTML을 그대로 노출시켜야하므로
   * 해당 함수를 사용하여 sanitize 처리 하도록 한다.
   **/
  safeHtmlContents(html) {
    let decodeStr = html?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') || '';
    decodeStr = decodeStr?.replace(
      /<[^>]*\s(?:on\w+=|javascript:|<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>).*?>/gi,
      ''
    );

    return DOMPurify.sanitize(decodeStr);
  },
};
