import axios from 'axios';
import { UploadTplCondition, UploadTplConditionSave } from '../../models/admin/UploadTemplate';
import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from '../../models/common/RestApi';
import { callApi } from '../../utils/ApiUtil';

export const getTemplateList = async (condition: UploadTplCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/uploadTemplate/list',
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as [];
};

export const getTemplateHistoryList = async (condition: UploadTplCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/uploadTemplate/history',
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as [];
};

export const saveTplwithHistory = async (condition: UploadTplConditionSave) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: '/v1/uploadTemplate/add',
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<any> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as [];
};

export const getTplAtchFileByCd = async (tplCd: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/uploadTemplate/fileId',
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ tplCd }),
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as object;
};

export const handleUploadTplAtchFileDownload = async (item) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/v1/file/history/download?atchFileGrId=${item.atchFileGrId}&atchFileId=${item.atchFileId}`,
      {
        responseType: 'blob',
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', item.tplFileNm);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
};
