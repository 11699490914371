/** @jsxImportSource @emotion/react */
import React, { useRef, useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { Clipboard, Point, Tooltip, PopupPosition, Rect } from '@grapecity/wijmo';
import { FlexGrid as FlexGridReact } from '@grapecity/wijmo.react.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import {
  HeadersVisibility,
  SelectionMode,
  CellType,
  CellRange,
  MergeManager,
  AllowPinning,
  AllowSorting,
  AllowDragging,
} from '@grapecity/wijmo.grid';
import { flexGridTooltip } from 'models/ip/Public';
import { CrudCode } from 'models/common/Edit';

interface Props {
  layoutDefinition: any[]; // 그리드 레이아웃
  rowData: object[]; // 바인딩 될 데이터
  height?: number; // 높이값
  isFilter?: boolean; // 필터 여부
  excludeFilter?: string[]; // 필터 제외 컬럼
  excludePin?: string[]; // 고정핀 미노출 컬럼
  isSelector?: boolean; // 행 체크박스 여부
  autoCheck?: boolean; // 행 클릭 시 체크박스 체크
  rowSelection?: 'single' | 'multiple'; // 행 선택유형
  showCheckAll?: boolean; // 전체선택 체크박스 노출여부 (강제제어가 필요한 경우에만 설정) (cf. MP의 트리구조에서 다건 선택이 가능하지만, 전체선택박스는 미노출 필요)
  onChangeCheckedItem?: (items: any[]) => void; // 체크박스 선택된 행 변경 이벤트
  style?: object; // ContentGrid의 style
  // 아래 FlexGrid 기본 속성값
  align?: string;
  allowSorting?: AllowSorting | boolean; // 정렬 허용 여부
  allowPinning?: AllowPinning | boolean; // 열 고정
  allowDragging?: AllowDragging | boolean; // 드래그 허용 여부
  showMarquee?: boolean; // 선택된 셀의 모양 false >> 셀전체 백그라운드
  showAlternatingRows?: boolean; // row라인별 색상구분 사용여부, [퍼블에서 강제 적용되어 Alternating 기능은 wijmo 그리드에서 무효화 됨.]
  alternatingRowStep?: number; // row라인별 색상구분, {2} => 2라인 단위로 교대로 백그라운드 색상.
  // autoGenerateColumns?: boolean; // false >> 컬럼 사용자 정의
  // stickyHeaders?: boolean; // 헤더 고정
  selectionMode?: SelectionMode; // row 선택 형태, default: Cell,  option: Row, RowRange, Cell, CellRange, ListBox, None
  headersVisibility?: HeadersVisibility; // 헤더열 표시여부, None, Column, Row, All  ;  wijmo.grid.HeadersVisibility.Column
  showSelectedHeaders?: HeadersVisibility; // Cell 이나 Row 선택 할 경우 헤드에 색상,
  deferResizing?: boolean; // Cell 이나 Row 넓이 변경 할 경우, default :false,    True >> 마우스 드래그 = 라인이 표시, 드롭 = 넓이 변경,
  isReadOnly?: boolean; // 읽기 전용,  헤드 레이아웃에서의 설정도 무효화 됨,
  frozenColumns?: number; // 좌우 스크롤,  고정될 컬럼(왼쪽에서부터)
  // formatItem?: Event<FlexGrid, FormatItemEventArgs>; //툴팁 적용 메서드
  // selectionChanged?: Event<FlexGrid, CellRangeEventArgs>; //로우가 변경될때 마다 실행 될 메서드
  selectionChanged?: (grid, e) => void; //로우가 변경될때 마다 실행 될 메서드
  initialized?: (grid) => void; //데이터가 그리드에 바인딩 되고 나서 실행 될 메서드
  beginningEdit?: (grid, e) => void;
  loadedRows?: (grid, e) => void;
  itemFormatter?: (panel, row, col, cell) => void;
  cellEditEnding?: (grid, e) => void;
  cellEditEnded?: (grid, e) => void;
  rowEditEnded?: (grid, e) => void;
  itemsSourceChanged?: (e) => void;
  draggingRow?: (grid, e) => void;
  draggedRow?: (grid, e) => void;
  childItemsPath?: string[]; // 하위 항목
}

const CustomGrid = ({
  layoutDefinition,
  rowData,
  height = 400,
  isFilter = true,
  isSelector = true,
  autoCheck = false,
  excludeFilter = [],
  excludePin = [],
  rowSelection = 'multiple',
  showCheckAll,
  onChangeCheckedItem,
  style,
  allowPinning = AllowPinning.ColumnRange,
  ...gridProps
}: Props) => {
  const { t } = useTranslation();
  const gridRef = useRef<any>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const headerHight = useMemo(() => {
    const findMaxDepth = (arr) => {
      const depthOf = (obj, currentDepth) => {
        if ('columns' in obj && Array.isArray(obj?.columns)) {
          return Math.max(...obj.columns.map((o) => depthOf(o, currentDepth + 1)));
        }
        return currentDepth;
      };
      return Math.max(...arr.map((o) => depthOf(o, 1)));
    };
    const rowHeight = 36;
    const depth = Math.max(findMaxDepth(layoutDefinition || []), 1);
    return rowHeight * depth;
  }, [layoutDefinition]);

  const changeCheckedItems = () => {
    const items = gridRef.current.rows.filter((r) => r.isSelected).map((o) => o.dataItem);
    onChangeCheckedItem && onChangeCheckedItem(items);
  };

  useEffect(() => {
    const rowCount = (rowData || []).length;
    setTotalCount(rowCount);
    // 행추가, 행복사 등 그리드 행이 증가했을 때 스크롤 최상단으로 이동
    if (totalCount > 0 && rowCount > 0 && totalCount < rowCount) {
      gridRef.current.scrollPosition = new Point(gridRef.current.scrollPosition.x, 0);
    }
  }, [rowData]);

  return (
    <ContentGrid className={(rowData || []).length < 1 ? 'noData' : ''} style={style}>
      <FlexGridReact
        {...gridProps}
        columnGroups={layoutDefinition}
        itemsSource={rowData}
        autoGenerateColumns={false}
        stickyHeaders={true}
        allowPinning={allowPinning}
        copying={(s, e) => {
          e.cancel = true;
          // 현재 선택된 셀 값 가져오기
          const data = s.getCellData(e.row, e.col, true);
          if (data) {
            Clipboard.copy(data.toString());
          }
        }}
        formatItem={(sender, args) => {
          if (args.panel.cellType === CellType.ColumnHeader && allowPinning) {
            // 고정핀 제외 컬럼
            const column = args.getColumn();
            if ([...excludePin, 'crudKey', 'no'].includes(column.binding || '')) {
              const pin = args.cell.querySelector('button.wj-elem-pin');
              pin && args.cell?.removeChild(pin);
            }
          }
          // flexGridTooltip(sender, args);
        }}
        initialized={(grid) => {
          gridRef.current = grid;
          const tt = new Tooltip({
            cssClass: 'hdr-tip',
          });
          const getElementText = (cell) => {
            if (cell.querySelector('.wj-control')) {
              return cell.querySelector('input').value;
            }
            return cell.textContent;
          };

          const showTooltip = (elem, pageX, pageY) => {
            const txt = getElementText(elem);
            const rc = new Rect(pageX, pageY + 10, 10, 10);
            tt.show(elem, txt, rc, PopupPosition.RightTop);
          };

          grid.hostElement.addEventListener('mouseover', (e) => {
            const ht = grid.hitTest(e);
            if (ht.panel === grid.cells) {
              const elem = ht.panel.getCellElement(ht.row, ht.col);
              showTooltip(elem, e.pageX, e.pageY);
            }
          });

          grid.hostElement.addEventListener('mousemove', (e: MouseEvent) => {
            const ht = grid.hitTest(e);
            if (ht.panel === grid.cells) {
              const elem = ht.panel.getCellElement(ht.row, ht.col);
              showTooltip(elem, e.pageX, e.pageY);
            }
          });

          grid.hostElement.addEventListener('mouseout', (e) => {
            tt.dispose();
          });

          // 초기 로드 시 첫 행 선택 방지
          grid.select(-1, -1);

          if (isSelector) {
            new Selector(grid, {
              showCheckAll:
                typeof showCheckAll === 'boolean' ? showCheckAll : rowSelection === 'multiple',
              itemChecked: (s, e) => {
                // 행 다건 선택인 경우
                if (rowSelection === 'multiple') {
                  changeCheckedItems();
                }
              },
            });
            // TopLeft 영역 병합
            grid.mergeManager = new CustomMergeManager();
          } else {
            grid.rowHeaders.columns.splice(0, 1);
          }
          if (isFilter) {
            // 필터 제외 컬럼
            const exclude = [...excludeFilter, 'crudKey', 'no'];
            const convertFlat = (acc, cur) => {
              if ('columns' in cur && Array.isArray(cur?.columns)) {
                return cur.columns.reduce(convertFlat, acc);
              }
              return !exclude.includes(cur?.binding) ? [...acc, cur?.binding] : acc;
            };

            const filterColumns = (layoutDefinition || []).reduce(convertFlat, []);

            new FlexGridFilter(grid, {
              filterColumns: filterColumns,
            });
          }

          grid.hostElement.addEventListener('click', (e) => {
            const ht = grid.hitTest(e);
            if (ht.target.type === 'checkbox' && ht.cellType === CellType.RowHeader) {
              // 행 단건 선택인 경우
              if (rowSelection === 'single') {
                const row = grid.rows[ht.row];
                // 현재 선택한 행을 제외하고 선택 해제 처리
                if (row.isSelected) {
                  grid.rows.map((o, idx) => {
                    if (idx !== ht.row) {
                      o.isSelected = false;
                    }
                  });
                  changeCheckedItems();
                }
              }
            }
            if (CellType.Cell === ht.cellType) {
              // 행 클릭 시 체크박스 선택
              if (isSelector && autoCheck) {
                const row = grid.rows[ht.row];
                row.isSelected = !row.isSelected;
                // 행 단건 선택인 경우
                if (rowSelection === 'single') {
                  // 현재 선택한 행을 제외하고 선택 해제 처리
                  grid.rows.map((o, idx) => {
                    if (idx !== ht.row) {
                      o.isSelected = false;
                    }
                  });
                }
                changeCheckedItems();
                grid.refresh();
              }
              // 셀 클릭 시 편집모드 실행 (singleClickEdit)
              if (ht.row > -1 && ht.col > -1) {
                grid.startEditing(true, ht.row, ht.col);
              }
              // dataMap 있는 경우 토글 (스크롤있는 경우 콤보박스 오동작 방지)
              const col = ht.panel.columns[ht.col];
              if (col?.dataMap && !col?.isReadOnly) {
                grid.toggleDropDownList();
              }
            }
          });

          gridProps?.initialized && gridProps?.initialized(grid);
        }}
        cellEditEnding={(grid, e) => {
          const oldVal = grid.getCellData(e.row, e.col, true);
          const newVal = grid.activeEditor?.value;
          const data = grid.rows[e.row].dataItem;
          // 셀 값 변경 시 상태 값 변경 (crudKey)
          if (oldVal !== newVal && data.crudKey !== CrudCode.CREATE) {
            data.crudKey = CrudCode.UPDATE;
          }
          gridProps?.cellEditEnding && gridProps?.cellEditEnding(grid, e);
        }}
        style={{ height: (rowData || []).length < 1 ? '' : `${height + headerHight}px` }}
      />
      <div className="noData" style={{ height: (rowData || []).length < 1 ? `${height}px` : '' }}>
        <span>
          {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
        </span>
      </div>
    </ContentGrid>
  );
};

class CustomMergeManager extends MergeManager {
  getMergedRange(panel, r, c, clip = true) {
    const rng = new CellRange(r, c);
    if (panel.cellType === CellType.ColumnHeader || panel.cellType === CellType.TopLeft) {
      for (let i = rng.row; i < panel.rows.length - 1; i++) {
        if (panel.getCellData(i, rng.col, true) != panel.getCellData(i + 1, rng.col, true)) break;
        rng.row2 = i + 1;
      }
      for (let i = rng.row; i > 0; i--) {
        if (panel.getCellData(i, rng.col, true) != panel.getCellData(i - 1, rng.col, true)) break;
        rng.row = i - 1;
      }
      for (let i = rng.col; i < panel.columns.length - 1; i++) {
        if (panel.getCellData(rng.row, i, true) != panel.getCellData(rng.row, i + 1, true)) break;
        rng.col2 = i + 1;
      }
      for (let i = rng.col; i > 0; i--) {
        if (panel.getCellData(rng.row, i, true) != panel.getCellData(rng.row, i - 1, true)) break;
        rng.col = i - 1;
      }
    }
    return rng;
  }
}

export default CustomGrid;
