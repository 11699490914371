import { Crud } from 'models/common/Edit';

export interface FileUploadPopUpInit extends Crud {
  atchFileGrId?: string;
  atchFileTpCd: string;
  optValCtn1: string;
}
export interface FileInfo extends Crud {
  atchFileGrId: string;
  atchFileId: string;
  atchFileNm: string;
  sortOrd: string;
  atchFileSaveLocDivsCd: string;
  atchFileSaveNm: string;
  atchFileSize: number;
  atchFileEfnmNm: string;
  atchFileSavePathCtn: string;
  atchFileTpCd: string;
  optValCtn1: string;
  optValCtn2: string;
  optValCtn3: string;
  optValCtn4: string;
  optValCtn5: string;
  useYn: string;
  newYn?: boolean;
  file?: Blob;
}
export interface FileSaveResponse {
  atchFileGrId: string;
  fileSaveResult: FileSaveResult;
  fileCount: number;
}
export enum FileSaveResult {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  NONE = 'NONE',
}
