/** @jsxImportSource @emotion/react */
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { SelectBox, Selected, list, AllSelectWrap, multi } from 'components/selects/CustomSelect';
import { Code } from 'models/common/CommonCode';
import _ from 'lodash';
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

interface ComboBoxProps {
  options: Code[]; // 옵션 목록 (cf. {cmnCd: 코드, cmnCdNm: 코드명})
  placeholder?: string; // 값이 없는 경우 노출되는 문구 (ex. 선택)
  defaultValue?: string; // 기본값
  classNameWrap?: string; // <AllSelectWrap>에 추가되는 클래스명
  className?: string; // <SelectBox>에 추가되는 클래스명
  readOnly?: boolean;
  disabled?: boolean;
  isError?: boolean; // 에러여부
  msgError?: string; // 에러문구
  // isWrap가 true인 경우에는 <AllSelectWrap>에 추가, false인 경우 <SelectBox>에 추가
  style?: {
    width?: string;
    display?: string;
  };
  isWrap?: boolean; // <AllSelectWrap> 추가 여부 (default: true)
  onChange: (value: string) => void; // 변경 이벤트 (변경된 값을 반환)
}

/**
 * ComboBox 컴포넌트
 *
 * @param options
 * @param placeholder
 * @param defaultValue
 * @param className
 * @param readOnly
 * @param disabled
 * @param onChange
 * @constructor
 */
export const ComboBox = (props: ComboBoxProps) => {
  const { isError = false, msgError, style, isWrap = true, classNameWrap = '' } = props;
  const showError = useMemo(() => {
    return isError && !!msgError;
  }, [isError, msgError]);

  return isWrap ? (
    <AllSelectWrap className={`${showError ? 'error' : ''} ${classNameWrap}`} style={style}>
      <ComboxBoxSelect {...{ ...props, style: {} }} />
      {showError && <span>{msgError}</span>}
    </AllSelectWrap>
  ) : (
    <ComboxBoxSelect {...props} />
  );
};

function ComboxBoxSelect({
  options,
  placeholder,
  defaultValue,
  className,
  readOnly = false,
  disabled = false,
  onChange,
  style,
}: ComboBoxProps) {
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [isOpen, setOpen] = useState(false);
  const comboRef = useRef<any>(null);
  const currentName = useMemo(() => {
    const c = options?.filter((o) => o.cmnCd === currentValue);
    if (!_.isEmpty(c)) {
      return c[0].cmnCdNm;
    }
    return '';
  }, [currentValue, options]);

  useEffect(() => {
    // 초기화 시 값 변경을 위해 추가
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    document.addEventListener('mousedown', handleDetectsClick);
    return () => {
      document.removeEventListener('mousedown', handleDetectsClick);
    };
  }, []);

  /**
   * 외부 클릭 감지 이벤트
   * @param e
   */
  const handleDetectsClick = (e) => {
    const target = comboRef?.current;
    if (_.isEmpty(target)) return;
    if (!target.contains(e.target)) {
      setOpen(false);
    }
  };

  /**
   * ComboBox 값 변경 이벤트
   * @param e
   */
  const handleChangeOption = (e) => {
    const value = e.target.dataset.value;
    if (value !== currentValue) {
      setCurrentValue(value);
      onChange(value);
    }
  };

  const handleOnClick = () => {
    if (disabled || readOnly) return;
    setOpen(!isOpen);
  };

  return (
    <SelectBox ref={comboRef} className={className} style={style} onClick={handleOnClick}>
      <Selected
        placeholder={placeholder}
        value={currentName}
        readOnly={readOnly}
        disabled={disabled}
        className={readOnly ? 'table' : ''}
      />
      <ul css={list.ul(isOpen)}>
        {placeholder && (
          <li data-value={''} onClick={handleChangeOption} css={list.li(_.isEmpty(currentValue))}>
            {placeholder}
          </li>
        )}
        {options &&
          options.map((o) => (
            <li
              data-value={o.cmnCd}
              key={o.cmnCd}
              onClick={handleChangeOption}
              css={list.li(currentValue === o.cmnCd)}
            >
              {o.cmnCdNm}
            </li>
          ))}
      </ul>
    </SelectBox>
  );
}

interface MultiComboBoxProps {
  id: string;
  options: Code[]; // 옵션 목록 (cf. {cmnCd: 코드, cmnCdNm: 코드명})
  placeholder?: string; // 값이 없는 경우 노출될 문구
  defaultValue?: string[]; // 기본값
  uniqueValue?: string; // 유니크값 (특정 값은 1건만 선택되는 경우 (ex. MP 분류 중 UTILITY는 1건만 선택가능))
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  isError?: boolean; // 에러여부
  msgError?: string; // 에러문구
  onChange: (values: string[]) => void; // 변경 이벤트 (선택된 값 목록 반환)
}

/**
 * MultiComboBox 컴포넌트
 * @param id
 * @param options
 * @param placeholder
 * @param defaultValue
 * @param uniqueValue
 * @param className
 * @param readOnly
 * @param disabled
 * @param isError
 * @param msgError
 * @param onChange
 * @constructor
 */
export const MultiComboBox = ({
  id,
  options,
  placeholder,
  defaultValue = [],
  uniqueValue,
  className,
  readOnly = false,
  disabled = false,
  isError = false,
  msgError,
  onChange,
}: MultiComboBoxProps) => {
  const [currentValue, setCurrentValue] = useState<string[]>(defaultValue || []);
  const showError = useMemo(() => {
    return isError && !!msgError;
  }, [isError, msgError]);

  useEffect(() => {
    // 초기화 시 값 변경을 위해 추가
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  return (
    <AllSelectWrap className={`multiSelect ${showError ? 'error' : ''}`}>
      <FormControl css={multi.select}>
        <InputLabel id={`multiple-checkbox-label-${id}`} className={'multiSelectLabel'}>
          {!currentValue.length && placeholder}
        </InputLabel>
        <Select
          labelId={`multiple-checkbox-label-${id}`}
          id={`multiple-checkbox-${id}`}
          multiple
          value={currentValue || []}
          onChange={(event: SelectChangeEvent<typeof currentValue>) => {
            const {
              target: { value },
            } = event;

            let values = typeof value === 'string' ? value.split(', ') : value;
            if (uniqueValue && values.includes(uniqueValue)) {
              if (_.last(values) === uniqueValue) {
                values = [uniqueValue];
              } else {
                values = values.filter((o) => o !== uniqueValue);
              }
            }

            setCurrentValue(values);
            onChange(values);
          }}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) =>
            (options || [])
              .filter((o) => selected.includes(o.cmnCd || ''))
              .map((o) => o.cmnCdNm)
              .join(', ')
          }
          readOnly={readOnly}
          disabled={disabled}
        >
          {(options || []).map((o) => (
            <MenuItem
              key={o.cmnCd}
              value={o.cmnCd}
              className={currentValue.includes(o.cmnCd || '') ? 'check' : ''}
            >
              <Checkbox checked={currentValue.includes(o.cmnCd || '')} />
              <ListItemText>{o.cmnCdNm}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showError && <span>{msgError}</span>}
    </AllSelectWrap>
  );
};
